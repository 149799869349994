import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Tag } from 'component/Tag'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { TryOnProductStore } from 'saas/store/product/tryon/TryOnProductStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { route } from 'store/route'
import { nbsp } from 'util/typo'
import css from './ProductCard.module.scss'

type Props = {
  product: TryOnProductStore
}

export const ProductCard = observer(({ product }: Props) => {
  const { company_id } = productsSaas.it.config
  const { product_id, name, published } = product.json

  const onOpen = useLatestCallback(() => {
    route.push(`/company/${company_id}/product/${product_id}`)
  })

  return <Card width={240} vertical tertiary>
    <Vertical pad={16}>
      <Space right>
        <Tag success={published}>
          {published ? i18n('product.Published') : i18n('product.NotPublished')}
        </Tag>
      </Space>
      <Gap height={48} />
      <Typo size={10}>
        {i18n('product.TryOnWidget')}
      </Typo>
      <Gap height={4} />
      <Typo size={16} wrap>
        {name}
      </Typo>
      <Gap height={12} />
      <Typo size={12}>
        {nbsp}
      </Typo>
    </Vertical>
    <Vertical className={css.bottom}>
      <Button text sharp height={40} action={onOpen}>
        <Space opposite>
          <Typo>
            Manage
          </Typo>
          <Icon name="keyboard_arrow_right" />
        </Space>
      </Button>
    </Vertical>
  </Card>
})
