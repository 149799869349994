import { isEqual, isNil, omitBy } from 'lodash'

type Predicate = (value: unknown, key: string) => boolean

export function omit<T>(value: Partial<T>, predicate?: Predicate): T {
  return omitBy(value, predicate ?? isNil) as T
}

export function isEmptyObject(value: object): boolean {
  return Object.values(value).length === 0
}

export function equal<T>(a: T, b: T): boolean {
  return isEqual(a, b)
}

export function getKeys<T>(value: T | null | undefined): (keyof T)[] {
  if (!isObject(value)) return []
  return Object.keys(value) as (keyof T)[]
}

export function isObject(value: unknown): value is NonNullable<object> {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateObjectUnsafe(original: any, update: any): any {
  original = isObject(original) ? original : {}
  update = isObject(update) ? update : {}
  const copy = { ...original, ...update }
  for (const key of getKeys(copy)) {
    const prev = original[key]
    const next = update[key]
    if (!Object.hasOwn(update, key)) {
      copy[key] = prev
    } else if (next === undefined) {
      delete copy[key]
    } else if (next === null || Array.isArray(next)) {
      copy[key] = next
    } else if (isObject(next)) {
      copy[key] = updateObjectUnsafe(prev, next)
    } else {
      copy[key] = next
    }
  }
  return copy
}

export function updateObject<T extends object>(original: T, update: Partial<T>): T {
  return updateObjectUnsafe(original, update)
}
