import { makeAutoObservable, observable } from 'mobx'

import { TryOnProduct } from 'type/product/TryOnProduct'
import { equal, updateObject } from 'util/object'

export class SettingsEditStore {
  private _json: TryOnProduct

  private _lang: string | undefined
  private _theme: string | undefined
  // color
  private _primary: string | undefined
  private _text: string | undefined
  private _background: string | undefined
  //email
  private _on: boolean | undefined
  private _title: string | undefined
  private _description: string | undefined
  private _agreement: string | undefined
  private _delay: number | undefined
  private _timeout: number | undefined
  private _thanks: string | undefined

  constructor(json: TryOnProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this.applyJson(json)
  }

  get json(): TryOnProduct {
    return this._json
  }

  set json(value: TryOnProduct) {
    this._json = value
  }

  get lang(): string | undefined {
    return this._lang
  }

  set lang(value: string | undefined) {
    this._lang = value
  }

  get theme(): string | undefined {
    return this._theme
  }

  set theme(value: string | undefined) {
    this._theme = value
  }

  get primary(): string | undefined {
    return this._primary
  }

  set primary(value: string | undefined) {
    this._primary = value
  }

  get text(): string | undefined {
    return this._text
  }

  set text(value: string | undefined) {
    this._text = value
  }

  get background(): string | undefined {
    return this._background
  }

  set background(value: string | undefined) {
    this._background = value
  }

  get on(): boolean | undefined {
    return this._on
  }

  set on(value: boolean | undefined) {
    this._on = value
  }

  get title(): string | undefined {
    return this._title
  }

  set title(value: string | undefined) {
    this._title = value
  }

  get description(): string | undefined {
    return this._description
  }

  set description(value: string | undefined) {
    this._description = value
  }

  get agreement(): string | undefined {
    return this._agreement
  }

  set agreement(value: string | undefined) {
    this._agreement = value
  }

  get delay(): number | undefined {
    return this._delay
  }

  set delay(value: number | undefined) {
    this._delay = value == null ? undefined : Math.abs(value)
  }

  get timeout(): number | undefined {
    return this._timeout
  }

  set timeout(value: number | undefined) {
    this._timeout = value == null ? undefined : Math.abs(value)
  }

  get thanks(): string | undefined {
    return this._thanks
  }

  set thanks(value: string | undefined) {
    this._thanks = value
  }

  get update(): Partial<TryOnProduct> {
    const { lang, theme, primary, text, background, on, title, description, agreement, delay, timeout, thanks } = this
    const color = { primary, text, background }
    const email = { on, title, description, agreement, delay, timeout, thanks }
    return { lang, theme, color, email }
  }

  get changed(): boolean {
    const updated = updateObject(this.json, this.update)
    return !equal(this.json, updated)
  }

  get canSave(): boolean {
    return this.changed
  }

  private applyJson(json: TryOnProduct) {
    this._lang = json.lang
    this._theme = json.theme
    this._primary = json.color?.primary
    this._text = json.color?.text
    this._background = json.color?.background
    this._on = json.email?.on
    this._title = json.email?.title
    this._description = json.email?.description
    this._agreement = json.email?.agreement
    this._delay = json.email?.delay
    this._timeout = json.email?.timeout
    this._thanks = json.email?.thanks
  }
}
