import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { parseIntFinite } from 'util/number'

export const TimeoutInput = observer(() => {
  const { timeout } = productSaas.it.edit
  const value = timeout?.toFixed() ?? ''

  const onChange = useLatestCallback((value: string) => {
    productSaas.it.edit.timeout = parseIntFinite(value)
  })
  return <>
    <Typo size={14}>{i18n('widget.DoNotShowTheEmailPopupAgainAfterClosing')}</Typo>
    <Gap height={0} />
    <Typo size={12} secondary>{i18n('widget.InHours')}</Typo>
    <Gap height={4} />
    <Input lazy value={value} placeholder="0" onChange={onChange} />
  </>
})
