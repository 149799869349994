import { production } from 'app/config/constants'
import { makeAutoObservable, observable } from 'mobx'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { KeyEditStore } from 'saas/store/product/tryon/KeyEditStore'
import { NameEditStore } from 'saas/store/product/tryon/NameEditStore'
import { SettingsEditStore } from 'saas/store/product/tryon/SettingsEditStore'
import { productsConfig } from 'saas/store/products/ProductsConfigStore'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { json5 } from 'util/json5'
import { omit, updateObject } from 'util/object'

function assembleCode(company: string, json: TryOnProduct) {
  const host = production ? 'try-on.showoff.app' : 'try-on-stage.showoff.app'
  const { lang, access } = json
  const { on, delay, timeout, title, description, agreement, thanks } = json.email ?? {}

  const email = !on ? undefined : omit({
    on,
    delay,
    timeout,
    title,
    description,
    agreement,
    thanks,
  }, v => v == null || v === '')

  const options = omit({
    company,
    access,
    lang,
    email,
  }, v => v == null || v === '')

  const _options = json5.stringify(options, { space: 2 }).replaceAll('\n', '\n    ')

  // language=html
  return `
<script>
  function setupShowoff() {
    const queue = []
    function open(parameters) {
      queue.push(['open', parameters])
    }
    window.showoff = { queue, open }
  }
  setupShowoff(${_options})
</script>

<script async src="https://${host}/widget.js"></script>
`.trim()
}

export class TryOnProductStore {
  readonly company_id: string
  private _json: TryOnProduct
  private _texts = false
  private _edit!: SettingsEditStore
  private _nameEdit?: NameEditStore
  private _keyEdit?: KeyEditStore

  constructor(company_id: string, json: TryOnProduct) {
    this.company_id = company_id
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this.reset()
  }

  get busy(): boolean {
    const config = productsConfig.get(this.company_id)
    return config.busy
  }

  get code(): string {
    return assembleCode(this.company_id, this.json)
  }

  get product_id(): string {
    return this._json.product_id
  }

  findKey(value: string | undefined): ApiKeyStore | undefined {
    return value ? this.company.keys.find(k => k.value === value) : undefined
  }

  get key(): ApiKeyStore | undefined {
    return this.findKey(this.json.access)
  }

  get json(): TryOnProduct {
    return this._json
  }

  get company(): CompanySaasStore {
    const company = companiesSaas.companies.find(c => c.company_id === this.company_id)
    if (!company) throw new Error('no company')
    return company
  }

  get texts(): boolean {
    return this._texts
  }

  set texts(value: boolean) {
    this._texts = value
  }

  get edit(): SettingsEditStore {
    return this._edit
  }

  get nameEdit(): NameEditStore | undefined {
    return this._nameEdit
  }

  get keyEdit(): KeyEditStore | undefined {
    return this._keyEdit
  }

  openNameEdit() {
    this._nameEdit = new NameEditStore(this._json)
  }

  closeNameEdit() {
    this._nameEdit = undefined
  }

  openKeyEdit() {
    const key = this.key
    this._keyEdit = new KeyEditStore({ key })
  }

  closeKeyEdit() {
    this._keyEdit = undefined
  }

  async saveSettings() {
    const { update } = productSaas.it.edit
    this.json = updateObject(this._json, update)
    await this.save()
  }

  async saveRename() {
    const nameEdit = productSaas.it.nameEdit
    if (!nameEdit || !nameEdit.check()) return
    const update = nameEdit.buildUpdate()
    this.json = updateObject(this._json, update)
    this.closeNameEdit()
    await this.save()
  }

  async saveKey() {
    const keyEdit = productSaas.it.keyEdit
    if (!keyEdit || !keyEdit.check()) return
    const update = keyEdit.buildUpdate()
    this.json = updateObject(this._json, update)
    this.closeKeyEdit()
    await this.save()
  }

  async publish(publish: boolean) {
    if (!!this.json.published === publish) return
    const published = publish ? true : undefined
    const update = { published }
    this.json = updateObject(this._json, update)
    await this.save()
  }

  reset() {
    this._texts = false
    this._edit = new SettingsEditStore(this._json)
  }

  set json(json: TryOnProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this.edit.json = this.json
  }

  private async save() {
    const config = productsConfig.get(this.company_id)
    await config.updateProduct(this.json)
  }
}
