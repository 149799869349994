import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { View } from 'saas/page/product/View'
import { Page } from 'saas/screen/Page'
import { closeProductSaas, productSaas } from 'saas/store/product/ProductSaasController'
import { home } from 'store/home'
import { route } from 'store/route'
import { renderAsyncState } from 'util/render'

export const SaasProductPage = observer(() => {
  const company_id = route.unit(1)
  const product_id = route.unit(3)
  const company = home.companies.companies.find(co => co.company_id === company_id)
  const current = productSaas.optional

  useMemo(() => {
    if (company) home.company.it = company
    if (company_id && product_id) productSaas.select(company_id, product_id)
    else closeProductSaas()
  }, [company, company_id, product_id])

  useEffect(() => () => current?.reset(), [current])
  useEffectOnce(() => () => closeProductSaas())

  const state = renderAsyncState(productSaas)
  if (state) return state

  return <Page pad={0}>
    <View />
  </Page>
})
