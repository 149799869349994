import { makeAutoObservable, runInAction } from 'mobx'
import { AsyncEntry } from 'store/base/async/AsyncEntry'

type Factory<Store, Keys extends unknown[]> = (...keys: Keys) => Promise<Store | undefined>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class AsyncStoreController<Store = any, Keys extends unknown[] = any> {
  private readonly cache: Record<string, AsyncEntry<Store>> = {}
  private readonly factory: Factory<Store, Keys>
  private current?: AsyncEntry<Store>

  constructor(factory: Factory<Store, Keys>) {
    makeAutoObservable<this, 'factory'>(this, { factory: false })
    this.factory = factory
  }

  get entry(): AsyncEntry<Store> | undefined {
    return this.current
  }

  get optional(): Store | undefined {
    return this.current?.store
  }

  get absent(): boolean {
    return !!this.current && this.current.absent
  }

  get(...keys: Keys): Store {
    const k = JSON.stringify(keys)
    const cached = this.cache[k]
    if (!cached) throw new Error('no cached ' + keys)
    return cached.it
  }

  get it(): Store {
    const it = this.optional
    if (!it) throw new Error('no it')
    return it
  }

  init(...keys: Keys): AsyncEntry<Store> {
    const k = JSON.stringify(keys)
    const cached = this.cache[k]
    if (cached) return cached

    const entry = new AsyncEntry(() => this.factory(...keys))
    this.cache[k] = entry
    return entry
  }

  load(...keys: Keys): AsyncEntry<Store> {
    const entry = this.init(...keys)
    void entry.load()
    return entry
  }

  select(...keys: Keys): AsyncEntry<Store> {
    const entry = this.load(...keys)
    runInAction(() => this.current = entry)
    return entry
  }

  close() {
    this.current = undefined
  }
}
