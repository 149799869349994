import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { TryOnProductStore } from 'saas/store/product/tryon/TryOnProductStore'
import { ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { Product } from 'type/product/Product'
import { TRY_ON } from 'type/product/ProductType'
import { extractProducts } from 'type/product/utils'
import { notNil } from 'util/null'

export function createProduct(company_id: string, json: Product): ProductSaasStore | undefined {
  const { type } = json
  switch (type) {
    case TRY_ON:
      return new TryOnProductStore(company_id, json)
    default:
      console.error('unknown product', company_id, json)
      return undefined
  }
}

export function createProducts(company_id: string, config: ProductsConfigStore): ProductSaasStore[] {
  const products = extractProducts(config.json)
  return products.map(json => createProduct(company_id, json)).filter(notNil)
}
