import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { ItemTag } from 'component/Tag'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { useRefState } from 'hook/useRefState'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import css from 'page/internal/products/tags/TagsSpace.module.scss'
import { MouseEvent, useEffect, useMemo, useRef } from 'react'
import { ProductStore } from 'store/product'

import { getShortcut } from 'util/keyboard'

type Props = {
  product: ProductStore
}

type State = {
  allowDeleteItems?: boolean
}

export const TagsSpace = observer(({ product }: Props) => {
  const { tags, text, changed, busy } = product.tags
  const state = useMemo<State>(() => ({}), [])
  const spaceRef = useRef<HTMLDivElement>(null)
  const ghostRef = useRef<HTMLDivElement>(null)
  const [input, setInput] = useRefState<HTMLInputElement>()

  const onClick = useLatestCallback((event: MouseEvent) => {
    const space = spaceRef.current
    if (!input || !space) return
    if (event.target !== space) return
    if (event.detail === 1) {
      input.selectionStart = 1e9
      input.focus()
    }
  })

  const onChangeText = useLatestCallback((value: string) => {
    if (busy) return
    text.onChange(value)
  })

  const onRemove = useLatestCallback((_event: unknown, tag: string) => {
    tags.data.remove(tag)
  })

  const onUndo = useLatestCallback(() => {
    product.tags.undo()
    input?.focus()
  })

  const onSave = useLatestCallback(async () => {
    if (!busy) await product.saveTags()
  })

  const onKeyDown = useLatestCallback((event: KeyboardEvent) => {
    if (busy) return
    const shortcut = getShortcut(event)
    if (shortcut === 'Enter') {
      product.tags.addTextTag()
    }
    if (shortcut === 'Ctrl+Enter') {
      if (!busy) void product.saveTags()
    }
    if (shortcut === 'Backspace') {
      if (state.allowDeleteItems == null) {
        state.allowDeleteItems = !text.value
      }
      if (!state.allowDeleteItems) return
      product.tags.removeLastTag()
    }
  })
  const onKeyUp = useLatestCallback((event: KeyboardEvent) => {
    const shortcut = getShortcut(event)
    if (!shortcut.includes('+')) {
      state.allowDeleteItems = undefined
    }
  })

  useEffect(() => {
    if (!input) return
    input.addEventListener('keydown', onKeyDown)
    input.addEventListener('keyup', onKeyUp)
    return () => {
      input.removeEventListener('keydown', onKeyDown)
      input.removeEventListener('keyup', onKeyUp)
    }
  }, [input, onKeyDown, onKeyUp])

  useEffect(() => {
    const ghost = ghostRef.current
    if (!input || !ghost) return
    const width = ghost.getBoundingClientRect().width
    input.style.width = Math.round(width).toFixed() + 'px'
  }, [input, text.value])

  return <Space ref={spaceRef} className={clsx(css.card, busy && css.disabled)} wrap onClick={onClick}>
    {tags.data.map(tag => <ItemTag key={tag} className={css.tag}
      data={tag} onClose={busy ? undefined : onRemove}>
      {tag}
    </ItemTag>)}
    <Space className={css.pane} gap={4} opposite>
      <Input ref={setInput} className={css.input} small value={text.value}
        onChange={onChangeText} clear={false} />
      <Space gap={3} flex="0 0 auto">
        {changed && <IconButton className={css.button} small
          action={onUndo} disabled={busy}>
          <Icon name="undo" size={16} />
        </IconButton>}
        {changed && <IconButton className={css.button} small
          action={onSave} disabled={busy} title={'Ctrl+Enter'}>
          <Icon name="check" size={16} />
        </IconButton>}
      </Space>
    </Space>
    <div ref={ghostRef} className={css.ghost}>
      <Typo size={14}>{text.value}</Typo>
    </div>
  </Space>
})
