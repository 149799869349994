import { rest } from 'app/rest'
import { makeAutoObservable, reaction } from 'mobx'
import { auth } from 'store/auth'
import { cfg } from 'store/cfg'
import { ui } from 'store/home/ui/HomeUiStore'
import { me } from 'store/me'
import { AUTH, route } from 'store/route'
import { socket } from 'store/socket/socket'
import { LoginResponse } from 'type/LoginResponse'
import { MeResponse } from 'type/MeResponse'

class SecurityController {
  private _busy = false

  constructor() {
    makeAutoObservable(this)
  }

  start() {
    reaction(() => this.act(), (act) => {
      if (act === 'auth') void this.auth()
      if (act === 'me') void this.me()
    }, { fireImmediately: true })
  }

  login(url: string) {
    route.login(url)
  }

  logout(): void {
    socket.close()
    auth.clear()
    me.clear()
    cfg.clear()
    localStorage.clear()
    route.logout()
  }

  private set busy(value: boolean) {
    this._busy = value
  }

  private act() {
    if (route.pathname === AUTH) return 'auth'
    if (!me.present && !route.open) return 'me'
  }

  private async auth() {
    if (this._busy) return
    this.busy = true
    try {
      const query = new URLSearchParams(location.search)
      const code = query.get('code') as string
      const redirect_uri = location.origin + AUTH
      const response = await rest.post<LoginResponse>('/api/auth/login', { code, redirect_uri })
      auth.auth = response.auth
      me.user = response.user
      me.permissions = response.permissions
      cfg.cfg = response.cfg
      socket.connect()
      route.start(ui.creator)
    } catch (e) {
      console.error(e)
      this.logout()
    } finally {
      this.busy = false
    }
  }

  private async me() {
    if (this._busy) return
    this.busy = true
    try {
      if (!auth.present) {
        this.logout()
        return
      }
      const response = await rest.post<MeResponse>('/api/auth/me', auth.auth)
      if (response.auth) auth.auth = response.auth
      me.user = response.user
      me.permissions = response.permissions
      cfg.cfg = response.cfg
      socket.connect()
      route.start(ui.creator)
    } catch (e) {
      console.error(e)
      this.logout()
    } finally {
      this.busy = false
    }
  }
}

export const security = new SecurityController()
