// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs-breadcrumbs-c9e3{border-bottom:1px solid var(--ui-border-color-secondary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,8BACE,wDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `Breadcrumbs-breadcrumbs-c9e3`
};
module.exports = ___CSS_LOADER_EXPORT___;
