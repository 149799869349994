import { Typography } from 'antd'
import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { MouseEvent, ReactNode } from 'react'
import { route } from 'store/route'
import { getPaddingStyle, Padding, styles } from 'util/style'
import css from './Link.module.scss'

interface Props {
  action?(): unknown
  href?: string
  path?: string
  text?: boolean
  secondary?: boolean
  small?: boolean
  wrap?: boolean
  pad?: Padding
  className?: string
  children?: ReactNode
}

const LinkAntd = Typography.Link

export const Link = ({ action, href, path, text, secondary, small, wrap, children, ...props }: Props) => {
  const className = clsx(props.className, css.link, secondary && css.secondary,
    text && css.text, small && css.small, wrap && css.wrap)

  const pad = getPaddingStyle(props.pad)
  const style = styles(pad)

  const link = href ?? path ?? ''
  const target = href ? '_blank' : undefined
  const rel = href ? 'noopener' : undefined

  const onClick = useLatestCallback((event: MouseEvent) => {
    const { metaKey, ctrlKey, altKey, shiftKey } = event
    const meta = Boolean(metaKey || ctrlKey || altKey || shiftKey)
    if (meta) return

    if (action) {
      event.preventDefault()
      action()
    } else if (path) {
      event.preventDefault()
      route.push(path)
    }
  })

  return <LinkAntd className={className} style={style} href={link}
    target={target} rel={rel} disabled={!link} onClick={onClick}>
    {children}
  </LinkAntd>
}
