import { split } from 'util/array'
import { formatCurrency } from 'util/currency'
import { nil } from 'util/null'
import { parseFinite } from 'util/number'
import { compareIgnoreCase, comparingKnownStrings, notEmptyString } from 'util/string'

export type DemoCatalogProduct = {
  item_id: string
  product_name?: string | null
  brand_name?: string | null
  dress_gender?: string | null
  dress_styles?: string | null
  prices?: string | null
  images?: string[] | null
  links?: string | null
}

export type DemoCatalogResponse = {
  items: DemoCatalogProduct[]
}

export function fixGender(value: string | nil): string {
  return value?.toLowerCase().trim() || 'none'
}

export function extractGender(it: DemoCatalogProduct): string {
  return fixGender(it.dress_gender)
}

export function extractPrice(it: DemoCatalogProduct): number | undefined {
  const text = it.prices?.split(':')[1]
  return parseFinite(text)
}

export function formatPrice(it: DemoCatalogProduct): string | undefined {
  const [currency, price] = it.prices?.split(':') ?? []
  return formatCurrency(price, currency)
}

export function extractBrands(items: DemoCatalogProduct[]) {
  const values = items.map(it => it.brand_name).filter(notEmptyString)
  return Array.from(new Set(values)).sort(compareIgnoreCase)
}

export function extractStyles(items: DemoCatalogProduct[]) {
  const styles = new Set<string>()
  for (const item of items) {
    const values = split(item.dress_styles).filter(notEmptyString)
    for (const value of values) {
      styles.add(value)
    }
  }
  return Array.from(styles).sort(compareIgnoreCase)
}

const compareGenders = comparingKnownStrings(['female', 'male'], [''])

export function extractGenders(items: DemoCatalogProduct[]) {
  const values = items.map(extractGender).filter(notEmptyString)
  return Array.from(new Set(values)).sort(compareGenders)
}
