import { app_domain } from 'app/config/constants'
import { getAppLink } from 'app/config/title'
import { Link } from 'component/Link'
import { observer } from 'mobx-react-lite'
import css from './DemoCatalogLogo.module.scss'
import LogoDresscodeSvg from './logo-dresscode.svg'
import LogoShowoffSvg from './logo-showoff.svg'

export const DemoCatalogLogo = observer(() => {
  const link = getAppLink()
  const dresscode = app_domain === 'dresscode'

  return <Link href={link}>
    <div className={css.logo}>
      {dresscode ? <LogoDresscodeSvg /> : <LogoShowoffSvg />}
    </div>
  </Link>
})
