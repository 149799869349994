import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'

export async function factory(company_id: string, product_id: string): Promise<ProductSaasStore | undefined> {
  await companiesSaas.load()
  const company = companiesSaas.companies.find(c => c.company_id === company_id)
  if (!company) return undefined
  const _company = company.load()
  await productsSaas.select(company_id).load()
  await _company
  const { products } = productsSaas.get(company_id)
  return products.find(product => product.product_id === product_id)
}

export const productSaas = new AsyncStoreController(factory)

export function closeProductSaas() {
  if (!productSaas.optional) return
  productSaas.it.closeKeyEdit()
  productSaas.it.closeNameEdit()
  productDeleteModal.close()
  // productSaas.close()
}
