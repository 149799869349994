export type Writeable<T> = { -readonly [P in keyof T]: T[P] }

export function mutable<V>(value: readonly V[]): V[]
export function mutable<T>(value: Readonly<T>): T
export function mutable(value: unknown): unknown {
  return value
}

export type Super<T> = T extends infer U ? U : object;

export type Destructor = () => void
