import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs } from 'saas/page/product/Breadcrumbs'
import { DetailsPanel } from 'saas/page/product/details/DetailsPanel'
import { Main } from 'saas/page/product/Main'

export const View = observer(() => {
  return <Vertical tall>
    <Breadcrumbs />
    <Space top opposite tall>
      <Main />
      <DetailsPanel />
    </Space>
  </Vertical>
})
