import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const LanguageSelect = observer(() => {
  const { lang } = productSaas.it.edit
  const en = lang === 'en'

  const onRu = useLatestCallback(() => {
    productSaas.it.edit.lang = 'ru'
  })

  const onEn = useLatestCallback(() => {
    productSaas.it.edit.lang = 'en'
  })

  return <Space gap={8}>
    <Button primary={!en} onClick={onRu}>
      {i18n('language.Russian')}
    </Button>
    <Button primary={en} onClick={onEn}>
      {i18n('language.English')}
    </Button>
  </Space>
})
