import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const Texts = observer(() => {
  const { title, description, agreement, thanks } = productSaas.it.edit

  const onClose = useLatestCallback(() => {
    productSaas.it.texts = false
  })

  const onChangeTitle = useLatestCallback((value: string) => {
    productSaas.it.edit.title = value.trim() || undefined
  })
  const onChangeDescription = useLatestCallback((value: string) => {
    productSaas.it.edit.description = value.trim() || undefined
  })
  const onChangeAgreement = useLatestCallback((value: string) => {
    productSaas.it.edit.agreement = value.trim() || undefined
  })
  const onChangeThanks = useLatestCallback((value: string) => {
    productSaas.it.edit.thanks = value.trim() || undefined
  })

  // title || i18n('widget.DefaultEmailLabel')
  // description || i18n('widget.DefaultEmailPlaceholder')
  // agreement || i18n('widget.DefaultSendEmailAgreement')
  // thanks || i18n('widget.DefaultSendEmailThanks')

  return <Card vertical pad={16} width={400} secondary>
    <Space gap={16} opposite>
      <Typo size={16} bold>{i18n('label.Texts')}</Typo>
      <IconButton link action={onClose}>
        <Icon size={16} name="close" secondary />
      </IconButton>
    </Space>
    <Gap height={16} />
    <Typo size={14} bold>{i18n('widget.EmailsCollection')}</Typo>
    <Gap height={16} />
    <Typo size={12} secondary>{i18n('widget.DefaultEmailLabel')}</Typo>
    <Gap height={4} />
    <Input value={title || ''} onChange={onChangeTitle} />
    <Gap height={12} />
    <Typo size={12} secondary>{i18n('widget.DefaultEmailPlaceholder')}</Typo>
    <Gap height={4} />
    <Input value={description || ''} onChange={onChangeDescription} />
    <Gap height={12} />
    <Typo size={12} secondary>{i18n('widget.DefaultSendEmailAgreement')}</Typo>
    <Gap height={4} />
    <Input value={agreement || ''} onChange={onChangeAgreement} />
    <Gap height={12} />
    <Typo size={12} secondary>{i18n('widget.DefaultSendEmailThanks')}</Typo>
    <Gap height={4} />
    <Input value={thanks || ''} onChange={onChangeThanks} />
  </Card>
})
