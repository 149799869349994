import { MxBox } from 'store/base/mx/MxBox'
import { MxRef } from 'store/base/mx/MxRef'

class MxController {
  box<V>(value: V): MxBox<V>
  box<V>(value?: V | undefined): MxBox<V | undefined>
  box<V>(value?: V | undefined): MxBox<V | undefined> {
    return new MxBox(value)
  }

  ref<V>(value?: V): MxRef<V> {
    return new MxRef(value)
  }
}

export const mx = new MxController()
