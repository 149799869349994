import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'
import css from './Breadcrumbs.module.scss'

export const Breadcrumbs = observer(() => {
  const { company_id } = productSaas.it
  const { product_id, name } = productSaas.it.json

  return <Space gap={4} pad={16} className={css.breadcrumbs}>
    <Link path={`/company/${company_id}/products`} secondary>
      {i18n('product.AllProducts')}
    </Link>
    <Icon size={16} name="chevron_right" secondary />
    <Link path={`/company/${company_id}/product/${product_id}}`} secondary>
      {name}
    </Link>
  </Space>
})
