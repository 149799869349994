import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { Title } from 'saas/page/product/name/Title'
import { Preview } from 'saas/page/product/Preview'
import { SettingsCard } from 'saas/page/product/settings/SettingsCard'
import { Texts } from 'saas/page/product/settings/Texts'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const Main = observer(() => {
  const { texts } = productSaas.it
  return <Vertical gap={16} pad={16}>
    <Title />
    <Space gap={16} parallel>
      {texts ? <Texts /> : <SettingsCard />}
      <Preview />
    </Space>
  </Vertical>
})
