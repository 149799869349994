// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyButton-copy-2bf2{display:inline-block;margin:-4px;padding:4px;font-size:14px;line-height:1;border-radius:var(--ui-border-radius);color:var(--ui-text-color-tertiary);cursor:pointer;opacity:.5;transition:opacity 200ms ease-in-out}.CopyButton-small-2bf2{font-size:12px;margin:-2px;padding:2px}.CopyButton-copy-2bf2:hover{opacity:1}.CopyButton-done-2bf2{color:var(--ant-color-success)}`, "",{"version":3,"sources":["webpack://./src/component/Button/CopyButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,WAAA,CACA,WAAA,CACA,cAAA,CACA,aAAA,CACA,qCAAA,CACA,mCAAA,CACA,cAAA,CACA,UAAA,CACA,oCAAA,CAGF,uBACE,cAAA,CACA,WAAA,CACA,WAAA,CAGF,4BACE,SAAA,CAGF,sBACE,8BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy": `CopyButton-copy-2bf2`,
	"small": `CopyButton-small-2bf2`,
	"done": `CopyButton-done-2bf2`
};
module.exports = ___CSS_LOADER_EXPORT___;
