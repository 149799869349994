// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Preview-stub-9e7e{background-color:var(--ui-background-color-weak)}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/Preview.module.scss"],"names":[],"mappings":"AAAA,mBACE,gDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stub": `Preview-stub-9e7e`
};
module.exports = ___CSS_LOADER_EXPORT___;
