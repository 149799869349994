import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const TextColorInput = observer(() => {
  const { text } = productSaas.it.edit
  const value = text ?? ''

  const onChange = useLatestCallback((value: string) => {
    productSaas.it.edit.text = value.trim()
  })

  return <>
    <Typo size={14}>{i18n('widget.TextColor')}</Typo>
    <Gap height={4} />
    <Input mono lazy value={value} placeholder="#000000" onChange={onChange} />
  </>
})
