import { initI18next } from 'i18n/config/config'
import { makeAutoObservable, runInAction } from 'mobx'

class LanguageController {
  private _ready = false

  constructor() {
    makeAutoObservable(this)
  }

  get ready(): boolean {
    return this._ready
  }

  async start() {
    await initI18next()
    runInAction(() => this._ready = true)
  }
}

export const lang = new LanguageController()
