import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { TitleEdit } from 'saas/page/product/name/TitleEdit'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { nbsp } from 'util/typo'

export const Title = observer(() => {
  const edit = !!productSaas.it.nameEdit
  const { name } = productSaas.it.json

  const onEdit = useLatestCallback(() => {
    productSaas.it.openNameEdit()
  })

  return <Space gap={16} opposite>
    <Vertical wide>
      <Typo size={12}>
        {edit ? nbsp : i18n('product.TryOnWidget')}
      </Typo>
      {!edit && <Space gap={8} height={32}>
        <Typo size={18} wrap>
          {name}
        </Typo>
        <IconButton small action={onEdit}>
          <Icon name="edit" size={16} />
        </IconButton>
      </Space>}
      {edit && <TitleEdit />}
    </Vertical>
  </Space>
})
