export function fixText(value: string): string {
  return value.replace(/\s+/g, ' ').trim()
}

export function validateEmail(value: string): boolean {
  // e@e.e
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export function validateHost(value: string): boolean {
  // example.com:22
  return /^(([a-zA-Z0-9-]){2,63}\.)+([a-zA-Z0-9-]){2,63}(:\d{2,5})?$/.test(value)
}
