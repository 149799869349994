import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const ThemeSelect = observer(() => {
  const { theme } = productSaas.it.edit
  const dark = theme === 'dark'

  const onRu = useLatestCallback(() => {
    productSaas.it.edit.theme = 'light'
  })

  const onEn = useLatestCallback(() => {
    productSaas.it.edit.theme = 'dark'
  })

  return <Space gap={8}>
    <Button primary={!dark} onClick={onRu}>
      {i18n('widget.ThemeLight')}
    </Button>
    <Button primary={dark} onClick={onEn}>
      {i18n('widget.ThemeDark')}
    </Button>
  </Space>
})
