import { clsx } from 'clsx'
import { CSSProperties } from 'react'
import { styles } from 'util/style'
import css from './Spinner.module.scss'

type Props = {
  className?: string
  size?: number
}

export const Spinner = (props: Props) => {
  const size = props.size ?? 25

  const className = clsx(props.className, css.container)
  const style = styles({ width: 1, '--spinner-width': size.toFixed() + 'px' })

  return <div className={className} style={style as CSSProperties}>
    <svg viewBox="0 0 50 50" className={css.spinner}>
      <circle className={css.ring} cx="25" cy="25" r="22.5" />
      <circle className={css.line} cx="25" cy="25" r="22.5" />
    </svg>
  </div>
}
